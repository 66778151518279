<template>
	<div id="about">
		<headers :activeItem="2" />

		<div class="banner">
			<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/banner.png" class="img_100" alt="" />
		</div>
		<div class="container">
			<div class="wrapper">
				<div class="left">
					<div class="about-txt">
						<h2>
							什么是么丢
							<i></i>
						</h2>
						<p>
							“么丢”小程序是公司独立研发的第三方信息服务平台，是服务于国家“双碳”发展规划应运而生的产物。通过深度数据整理采集、用户行为分析，智能匹配用户需求、推荐最优信息，提供发布商品“多人竞价+派单取送”相结合的新型回收服务模式，开创一种全新的以废旧物回收为特色功能的全品类回收交易平台。
						</p>
					</div>
					<div class="three-blk">
						<div class="blk">
							<img src="../assets/sm.png" alt="" />
							<h2>使命</h2>
							<p>循环经济践行者</p>
						</div>
						<div class="blk">
							<img src="../assets/yj.png" alt="" />
							<h2>愿景</h2>
							<p>全民低碳生活</p>
						</div>
						<div class="blk">
							<img src="../assets/qyjzg.png" alt="" />
							<h2>企业价值观</h2>
							<p>创新 诚信 格局 坚守</p>
						</div>
					</div>
				</div>
				<div class="right hidden-xs hidden-sm">
					<div class="two-pic">
						<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/twopix1.png" alt="" class="one" />
						<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/twopic2.png" alt="" class="two" />
					</div>
				</div>
			</div>
		</div>
		<footers :bottom="true" />
	</div>
</template>

<script>
import headers from './components/headers.vue';
import footers from './components/footers.vue';
export default {
	name: 'about',
	components: {
		headers,
		footers
	},
	data() {
		return {};
	},
	methods: {}
};
</script>

<style scoped>
#about {
	background: url('https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/bg1.png') no-repeat;
	background-size: cover;
	background-position: top;
	background-attachment: fixed;
	min-height: 100vh;
}

.wrapper {
	padding: 6rem 0 8rem;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: stretch;
}

.left {
	width: 52%;
	position: relative;
}

.about-txt h2 {
	color: rgba(0, 0, 0, 1);
	font-weight: bold;
	font-size: 1.8rem;
}

.about-txt h2 i {
	display: inline-block;
	vertical-align: top;
	width: 0.75rem;
	height: 0.75rem;
	border-radius: 100%;
	background: radial-gradient(56.67% 56.67% at 43.33% 53.33%, rgba(31, 77, 13, 0) 0%, rgba(50, 177, 0, 1) 100%);
}

.about-txt p {
	margin-top: 25px;
	text-align: justify;
	color: rgba(102, 102, 102, 1);
	font-weight: bold;
	font-size: 0.8rem;
	line-height: 1.3rem;
	letter-spacing: 1px;
}

.three-blk {
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	width: 100%;
}

.three-blk .blk {
	padding: 1.25rem 0;
	width: 8.5rem;
	height: 8.5rem;
	border-radius: 0.6rem;
	background: rgba(255, 255, 255, 1);
	box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.06);
	text-align: center;
	max-width: 30%;
}

.three-blk img {
	/* maxwidth: 1.75rem; */
	height: 1.75rem;
	display: block;
	margin: auto;
}

.three-blk h2 {
	margin-top: 0.75rem;
	color: rgba(51, 51, 51, 1);
	font-weight: bold;
	font-size: 1rem;
	line-height: 1.3rem;
}

.three-blk p {
	margin-top: 0.75rem;
	color: rgba(102, 102, 102, 1);
	font-size: 0.8rem;
	line-height: 1.3rem;
}

.right {
	width: 38%;
	padding-top: 3.5rem;
}

.two-pic {
	position: relative;
}

.two-pic .one {
	width: 22.15rem;
	height: auto;
	max-width: 100%;
}

.two-pic .two {
	width: 7.5rem;
	height: auto;
	max-width: 40%;
	position: absolute;
	right: -2rem;
	top: -2.5rem;
	/* border: 5px solid rgba(255, 255, 255, 1); */
}
</style>
