<template>
	<div id="footer">

		<div class="gap" v-if="bottom"></div>
		<footer :class="bottom?'bottom':''">
			<div class="container">
				<div class="row">
					<div class="col-md-12 col-sm-12">
						<p :class="bottom?'c33':'cff'">Coryright 2022 <a href="https://www.diuxz.cn/"
								target="_blank" :class="bottom?'c33':'cff'">河南循中网络科技有限公司</a></p>
						<p><img
								src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/officialWebsite/beianGov.png"><a
								:class="bottom?'c33':'cff'"
								href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702003119">豫公网安备
								41010702003119号</a></p>
						<p><a :class="bottom?'c33':'cff'" href="http://beian.miit.gov.cn/">豫ICP备2022014522号-2</a></p>
					</div>
				</div>
			</div>
		</footer>
	</div>
</template>

<script>
	export default {
		name: "footers",
		props: {
			bottom: {
				typr: String,
				default: false

			},
		},
		data() {
			return {}
		},
		methods: {}
	}
</script>

<style scoped>
	footer {
		position: absolute;
		bottom: .5rem;
		width: 100%;
		text-align: center;
	}

	footer.bottom {
		position: relative;
		padding: 1rem 0;
	}

	footer p {
		font-size: 0.7rem;
		line-height: 1.25rem;
	}

	footer img {
		width: 1rem;
		height: 1rem;
		display: inline-block;
		vertical-align: middle;
	}

	footer a:active,
	footer a:visited {
		color: #fff;
	}

	footer.bottom a:active,
	footer.bottom a:visited {
		color: #333;
	}

	footer.bottom a:hover {
		color: #1F4D0D;
	}


</style>
