<template>
	<div :class="whiteFlg?'navbar navbar-default nav-white':'navbar navbar-default'">
		<div class="container">
			<div class="navbar-header">
				<button type="button" id="nav-open" class="navbar-toggle collapsed" data-toggle="collapse"
					data-target="#navbar-collapse" aria-expanded="false" @click="openFlg = !openFlg">
					<span class="sr-only">Toggle navigation</span>
					<span class="icon-bar top-bar"></span>
					<span class="icon-bar middle-bar"></span>
					<span class="icon-bar bottom-bar"></span>
				</button>
				<a href="index.html" class="navbar-brand">
					<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/logo-green.png" class="img-responsive" v-if="whiteFlg" />
					<img src="https://xunzhong-1311798464.cos.ap-shanghai.myqcloud.com/upload/file/diuPc/logo.png" class="img-responsive" v-else />
				</a>
			</div>

			<div :class="openFlg?'collapse navbar-collapse in':'collapse navbar-collapse'" id="navbar-collapse">

				<ul class="nav navbar-nav">
					<li :class="activeItem === 0?'active':''">
						<router-link :to="{path:'/'}">首页</router-link>
					</li>
					<li :class="activeItem === 1?'active':''">
						<router-link :to="{path:'/list'}">回收列表</router-link>
					</li>
					<li :class="activeItem === 2?'active':''">
						<router-link :to="{path:'/about'}">关于我们</router-link>
					</li>
					<li :class="activeItem === 3?'active':''">
						<router-link :to="{path:'/contact'}">加入我们</router-link>
					</li>
				</ul>

			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "headers",
		props: {
			whiteFlg: {
				typr: Boolean,
				default: false

			},
			activeItem: {
				type: Number,
				default: 0

			},
		},
		data() {
			return {
				openFlg: false
			}
		},
		methods: {

		}
	}
</script>

<style scoped>
	.navbar {
		background: transparent;
		border: 0;
		z-index: 100;
		border-radius: 0;
		margin-bottom: 0;
		border-left: 0;
		border-right: 0;
		border-bottom: 0;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
		position: absolute;
		left: 0;
		right: 0;
		height: 5rem;
		/* background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%); */
	}

	.navbar .navbar-header {
		position: relative;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out;
	}

	.navbar-brand {
		padding: 0;
		position: absolute;
		top: .5rem;
		left: 0;
		height: 4rem;
		width: 4rem;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out
	}

	.navbar-brand img {
		width: auto;
		height: 100%;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out
	}

	.navbar-nav {
		margin: 0
	}

	.navbar.nav-white {
		box-shadow: 0.1rem 0.1rem 0.5rem rgba(0, 0, 0, 0.08);
	}

	.navbar-right {
		margin: 0
	}

	.navbar-right a:hover {
		color: #fff
	}

	.nav-white .navbar-right a:hover {
		color: #1F4D0D
	}

	.navbar>.container .navbar-brand {
		margin-left: 0
	}

	.navbar-default .navbar-nav>li {
		padding: 1.75rem 0 1rem 2rem;
		-webkit-transition: all .3s ease-in-out;
		transition: all .3s ease-in-out
	}

	.navbar-default .navbar-nav>li>a {
		color: #fff;
		font-size: .9rem;
		padding: 0;
		position: relative;
		line-height: 1.375;
	}

	.nav-white.navbar-default .navbar-nav>li>a {
		color: #333;
	}

	.navbar-default .navbar-nav>li>a>span {
		font-size: .6rem;
		line-height: 1.4
	}

	.navbar-default .navbar-nav>li>a:before {
		content: "";
		width: 0;
		height: 0.1rem;
		background: #FFFFFF;
		border-radius: 2px;
		position: absolute;
		bottom: -.35rem;
		left: 50%;
		margin-left: -47.5%;
		-webkit-transition: all .3s;
		-moz-transition: all .3s;
		-ms-transition: all .3s;
		-o-transition: all .3s;
		transition: all .3s;
	}

	.nav-white.navbar-default .navbar-nav>li>a:before {
		background: #1F4D0D;
	}

	.navbar-default .navbar-nav>.active>a,
	.navbar-default .navbar-nav>.active>a:focus,
	.navbar-default .navbar-nav>.active>a:hover {
		color: #fff;
		background-color: transparent;
	}

	.nav-white.navbar-default .navbar-nav>.active>a,
	.nav-white.navbar-default .navbar-nav>.active>a:focus,
	.nav-white.navbar-default .navbar-nav>.active>a:hover {
		color: #1F4D0D;
		font-weight: 500;
	}

	.navbar-default .navbar-nav>li>a:focus:before,
	.navbar-default .navbar-nav>li>a:hover:before,
	.navbar-default .navbar-nav>.active>a:before,
	.navbar-default .navbar-nav>.active>a:focus:before,
	.navbar-default .navbar-nav>.active>a:hover:before {
		width: 95%;
	}

	.navbar-default .navbar-nav>li>a:focus,
	.navbar-default .navbar-nav>li>a:hover,
	.navbar-default .navbar-nav>.open>a,
	.navbar-default .navbar-nav>.open>a:focus,
	.navbar-default .navbar-nav>.open>a:hover {
		color: #fff;
		background-color: transparent
	}

	.nav-white.navbar-default .navbar-nav>li>a:focus,
	.nav-white.navbar-default .navbar-nav>li>a:hover,
	.nav-white.navbar-default .navbar-nav>.open>a,
	.nav-white.navbar-default .navbar-nav>.open>a:focus,
	.nav-white.navbar-default .navbar-nav>.open>a:hover {
		color: #1F4D0D;
	}

	.navbar-default .navbar-collapse {
		position: relative;
		float: right;
		box-shadow: none;
		padding: 0 !important
	}

	.navbar-default .navbar-toggle {
		border: 0;
		transition: all .3s ease;
		-webkit-transition: all .3s ease;
		padding: 0;
		margin: .5rem 0
	}

	.navbar-default .navbar-toggle .icon-bar {
		background: #fff
	}

	.nav-white.navbar-default .navbar-toggle .icon-bar {
		background: #1F4D0D
	}

	.navbar-default .navbar-toggle:focus,
	.navbar-default .navbar-toggle:hover {
		background-color: transparent
	}

	.navbar-default .navbar-toggle:focus .icon-bar,
	.navbar-default .navbar-toggle:hover .icon-bar {
		background-color: #fff
	}

	.nav-white.navbar-default .navbar-toggle:focus .icon-bar,
	.nav-white.navbar-default .navbar-toggle:hover .icon-bar {
		background-color: #1F4D0D
	}

	.navbar .navbar-toggle .icon-bar {
		width: 2rem;
		-webkit-transition: all .2s;
		transition: all .2s;
		background: #fff;
		pointer-events: none;
		margin: .5rem 0
	}

	.navbar .navbar-toggle .top-bar {
		-webkit-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		transform: rotate(45deg);
		-webkit-transform-origin: 10% 10%;
		-ms-transform-origin: 10% 10%;
		transform-origin: 10% 10%;
		width: 2.2rem
	}

	.navbar .navbar-toggle .middle-bar {
		opacity: 0
	}

	.navbar .navbar-toggle .bottom-bar {
		-webkit-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-webkit-transform-origin: 5% 90%;
		-ms-transform-origin: 5% 90%;
		transform-origin: 5% 90%;
		width: 2.2rem
	}

	.navbar .navbar-toggle.collapsed .top-bar {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		transform: rotate(0);
		width: 2rem
	}

	.navbar .navbar-toggle.collapsed .middle-bar {
		opacity: 1
	}

	.navbar .navbar-toggle.collapsed .bottom-bar {
		-webkit-transform: rotate(0);
		-ms-transform: rotate(0);
		transform: rotate(0);
		width: 2rem
	}
</style>
